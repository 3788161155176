import React from 'react';
import Navbar from './navbar'; // Adjust path if needed
import '../styles/blog.css';

const Blog = () => {
  return (
    <div>
      <Navbar />
      <div className="content">
        <h1>We are currently working on it</h1>
      </div>
    </div>
  );
};

export default Blog;
